import React from 'react'
import Home from "views/home";
import AboutUs from "views/aboutUs";
import ContactUs from "views/contactUs";
import { Switch, Route } from "react-router";
import Services from 'views/services';
import Products from 'views/products';
import Header from 'components/header';

export default function Router() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/contact-us" component={ContactUs} />
      </Switch>
    </>
  )
}