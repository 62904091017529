import React from 'react'
import './serviceCard.scss'

export default function ServiceCard(props) {
  return <div className="service-card">
    <div className="image-container">
      <img className="top-img" src={require(`static/images/tab/${props.data.image}.png`)} alt="" />
    </div>
    <div className="service-data">
      <h1>{props.data.title}</h1>
      <p>{props.data.text}</p>
      <button onClick={() => { window.scrollTo(0, 0); props.showServiceDetail(props.index) }} className="light font-medium">Read more<span><img src={require('static/images/icons/arrow.svg')} alt="arrow" /></span></button>
    </div>
  </div>
}