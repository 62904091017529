import React from 'react'
import { reachOutToUs } from 'utils/constants'
import './reachOutToUs.scss'

export default function ReachOutToUs(props) {
  return <div className={`reach-out-to-us ${props.contactUs ? 'contact-us' : ''}`}>
    <h1>{props.heading ? props.heading : 'Need help? Reach out to us'}</h1>
    <div className="container">
      {reachOutToUs.map((reach, index) => {
        return <a href={`mailto:admin@dnassociates.net.in?subject=${reach.subject}`} className="info" key={index}>
          <img src={require(`static/images/reach-to-us/${reach.imageUrl}`)} alt="" />
          <p>{reach.text}</p>
        </a>
      })}
    </div>
    <img className="background" src={require('static/images/reach-to-us/reach-to-us-bg.svg')} alt="bg" />
  </div>
}