import React, { useState } from 'react'
import ServiceCard from 'components/serviceCard'
import { tabProductData, serviceData } from 'utils/constants'
import './services.scss'
import PageHead from 'components/pageHead'

export default function Services() {
  const [showServiceDetail, setShowServiceDetail] = useState(false)
  const [data, setData] = useState()
  let images = ['first', 'second', 'third', 'fourth']

  return <div className="service-page is-page">
    <PageHead title="Services" description="DN Associates is partnering with the leading international and national drug manufacturers, to cater all your healthcare needs. " />
    {/* <div className="carousel">
      <div className="slider" style={{ transform: `translate(${transformValue}%)` }}>
        {tabProductData.map((service, index) => {
          return <section key={index}><ServiceCard data={service} /></section>
        })}
      </div>
      <div className="controls">
      <button onClick={() => setTransformValue(transformValue - 20)} className="next"><i className="material-icons">{`>`}</i></button>
      <button onClick={() => setTransformValue(transformValue + 20)} className="prev"><i className="material-icons">{`<`}</i></button>
      </div>
    </div> */}
    {showServiceDetail ?
      <div className={`service-detail ${showServiceDetail ? 'show' : 'hide'}`}>
        <div className="left">
          <img className="cursor-pointer" src={require('static/images/services/cross.svg')} alt="close" onClick={() => setShowServiceDetail(false)} />
        </div>
        <div className="middle">
          <h3>{tabProductData[data].title}</h3>
          <div dangerouslySetInnerHTML={{
            __html: serviceData[data]
          }}></div>
        </div>
        <div className="right">
          <img src={require(`static/images/services/${images[data]}.png`)} alt="" />
        </div>
      </div>
      :
      <>
        <div className="services-scroll">
          {tabProductData.map((service, index) => {
            return <div key={index} className="element"><ServiceCard index={index} showServiceDetail={data => { setShowServiceDetail(true); setData(data) }} data={service} /></div>
          })}
        </div>
        <div className="scroll-text">
          <div>scroll to explore</div>
          <img src={require('static/images/services/right-arrow.svg')} alt="arrow" />
        </div>
      </>}
  </div>
}