import React from 'react'
import './contact-us.scss'
import Footer from 'components/footer'
import ReachOutToUs from 'components/reachOutToUs/reachOutToUs'
import PageHead from 'components/pageHead'

export default function ContactUs(props) {
  return <div className="contact-us-page">
    <PageHead title="Contact us" description="We look forward to hearing from you and taking care of all your healthcare needs." />
    <div className="banner">
      <img className="building" src={require('static/images/contact-us/building.png')} alt="building" />
    </div>
    <div className="filler"></div>
    <div className="address parent-container with-padding">
      <div className="left">
        <h2>Registered Office</h2>
        {/* <p>Putting customers at the core of our work and purpose.</p> */}
      </div>
      <div className="right">
        <a href="http://maps.google.com/?q=8 Sugnamal Medicine Market, 39 Aminabad, Lucknow, Uttar Pradesh 226018" className="cursor-pointer">
          <div className="contact-container">
            <img src={require('static/images/contact-us/location.svg')} alt="location" />
            <p>8 Sugnamal Medicine Market, 39 Aminabad, Lucknow, Uttar Pradesh 226018</p>
          </div>
        </a>
        <a href="tel:0522 400 5760" className="cursor-pointer">
          <div className="contact-container">
            <img src={require('static/images/contact-us/phone.svg')} alt="location" />
            <p>0522 400 5760</p>
          </div>
        </a>
      </div>
    </div>
    <div className="address parent-container with-padding">
      <div className="left">
        <h2>Corporate Office</h2>
        {/* <p>Putting customers at the core of our work and purpose.</p> */}
      </div>
      <div className="right">
        <a href="http://maps.google.com/?q=Phase II, Transport Nagar, Kanpur Road, Lucknow 226 012" className="cursor-pointer">
          <div className="contact-container">
            <img src={require('static/images/contact-us/location.svg')} alt="location" />
            <p>E-4 Phase II, Transport Nagar, Kanpur Road, Lucknow 226 012</p>
          </div>
        </a>
        <a href="tel:0522 4046883" className="cursor-pointer">
          <div className="contact-container">
            <img src={require('static/images/contact-us/phone.svg')} alt="location" />
            <p>0522 4046883</p>
          </div>
        </a>
      </div>
    </div>
    <div className="parent-container with-padding reach-out">
      <ReachOutToUs heading="For more info, get in touch with our team" contactUs />
    </div>
    <Footer history={props.history} />
  </div>
}