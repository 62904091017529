import React, { useState, useEffect, useRef } from 'react'
import './header.scss'
import { headerRoutes } from 'utils/constants'
import { withRouter } from 'react-router'

function useOutsideAlerter(ref, setShowDropdown) {
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }
}

function Header(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShowDropdown);

  return <div className="header-component flex-between parent-container with-padding">
    <div className="hamburger" onClick={() => setShowDropdown(!showDropdown)}><img src={require('static/images/menu.svg')} alt="" /></div>
    <div className="flex-1 cursor-pointer logo" onClick={() => props.history.push('')}><img src={require('static/images/logos/logo.svg')} alt="logo" /></div>
    <div className="empty-div"></div>
    <div className="nav-links flex-1">
      {headerRoutes.map((link, index) => {
        if (link.text.includes('contact us')) {
          return <button
            key={index}
            onClick={() => props.history.push(link.path)}
            className={`dark font-18 ${props.location.pathname === '/' + link.path ? 'active' : ''}`}
          >{link.text}</button>
        } else
          return <div
            key={index}
            onClick={() => props.history.push(link.path)}
            className={`header-link font-18 ${props.location.pathname === '/' + link.path ? 'active' : ''}`}
          >{link.text}</div>
      })}
    </div>
    <div ref={wrapperRef} className={`dropdown ${showDropdown ? 'show' : 'hide'}`}>
      {showDropdown && headerRoutes.map((link, index) => {
        return <div key={index} onClick={() => { setShowDropdown(!showDropdown); props.history.push(link.path) }}>{link.text}</div>
      })}
    </div>
  </div>
}
export default withRouter(Header)