import React, { useState } from 'react'
import './aboutUs.scss'
import Footer from 'components/footer'
import PageHead from 'components/pageHead'
import TabComponent from 'components/tabComponent'
import { tabAboutData, tabAboutHeader } from 'utils/constants'
import { partnerImg } from 'utils/constants'
import { certificates } from 'utils/constants'

export default function AboutUs(props) {
  const [transform, setTransform] = useState(0)
  const [certificateTransform, setCertificateTransform] = useState(0)
  const [showCertificate, setShowCertificate] = useState(false)

  return <div className="about-us-page">
    <PageHead title="About us" description="DN Associates has been working towards delivering all your healthcare needs with compassion over three decades." />
    <div className="banner">
      <h1>About DN Associates</h1>
    </div>
    <div className="description parent-container with-padding">
      <div className="info">
        <h1>Inspiration, care, compassion delivered always</h1>
        <p>We put patient in front and centre, rather than products. Dedicated to patient’s need with care & compassion since 1991. This is why our vision is to improve care in every setting.
One product, one partner, one patient at a time. We’re making this happen every day by touching virtually every aspect of healthcare.</p>
      </div>
      <div className="animated-logo">
        <img className="bg" src={require('static/images/animated-logo/colors.svg')} alt="logo" />
        <img className="dna" src={require('static/images/animated-logo/dna.svg')} alt="logo" />
        <img className="shadow" src={require('static/images/animated-logo/shadow.png')} alt="logo" />
        <img className="bottom-shadow" src={require('static/images/animated-logo/bottom-shadow.svg')} alt="logo" />
      </div>
    </div>
    <div className="filler"></div>
    <div className="mission">
      <img src={require('static/images/about-us/building.png')} alt="" />
      <div className="mission-info">
        {/* <h3>MISSION STATEMENT</h3> */}
        <p>One product, one partner, one patient at a time. We’re making this happen every day by touching virtually every aspect of healthcare.</p>
      </div>
    </div>
    <div className="team parent-container with-padding">
      <h1>Founders</h1>
      <div className="founder-container">
        <div className="founder-card">
          <img src={require('static/images/about-us/deepak.jpg')} alt="founder" />
          <h3 className="name">Deepak Manchanda</h3>
          <p className="title">Co-founder</p>
          {/* <p className="intro">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
        </div>
        <div className="founder-card">
          <img src={require('static/images/about-us/sandip.jpg')} alt="founder" />
          <h3 className="name">Sandip Manchanda</h3>
          <p className="title">Co-founder</p>
          {/* <p className="intro">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
        </div>
      </div>
    </div>
    <div className="tab-section">
      <TabComponent history={props.history} about tabs={tabAboutHeader} tabData={tabAboutData} />
    </div>
    {/* business partner carousel */}
    <div className="business-partners parent-container with-padding">
      <div className="heading">
        <h3>Business partners</h3>
        <div className="arrow-section">
          <img className={transform === 0 ? 'disabled' : ''} onClick={() => setTransform(transform + 20)} src={require('static/images/about-us/carousel-arrow.svg')} alt="" />
          <img className={transform === -100 ? 'disabled' : ''} onClick={() => setTransform(transform - 20)} src={require('static/images/about-us/carousel-arrow.svg')} alt="" />
        </div>
      </div>
      <div className="partner-carousel" style={{ transform: `translate(${transform}%)` }}>
        {partnerImg.map((partner, index) => {
          return <div key={index} className={`group ${(Math.abs(transform) / 20) === index ? '' : 'not-in-view'}`}>{partner.map((part, index) => {
            return <div className="partner" key={index}>
              <img src={require(`static/images/business-partner/${part}.png`)} alt="" />
            </div>
          })}
          </div>
        })}
      </div>
    </div>
    <div className="office">
      <img src={require('static/images/about-us/office.png')} alt="office-space" />
    </div>
    {/* certificates carousel */}
    <div className="business-partners parent-container with-padding">
      <div className="heading">
        <h3>Awards and appreciations</h3>
        <div className="arrow-section">
          <img className={certificateTransform === 0 ? 'disabled' : ''} onClick={() => setCertificateTransform(certificateTransform + (window.screen.width < 768 ? 25 : 10))} src={require('static/images/about-us/carousel-arrow.svg')} alt="" />
          <img className={certificateTransform === (window.screen.width < 768 ? -100 : -30) ? 'disabled' : ''} onClick={() => setCertificateTransform(certificateTransform - (window.screen.width < 768 ? 25 : 10))} src={require('static/images/about-us/carousel-arrow.svg')} alt="" />
        </div>
      </div>
      <div className="partner-carousel" style={{ transform: `translate(${certificateTransform}%)` }}>
        {certificates.map((certi, index) => {
          return <img onClick={() => setShowCertificate(certi)} className="certificate" key={index} src={require(`static/images/certificates/${certi}.jpg`)} alt="" />
        })}
      </div>
    </div>
    {showCertificate && <div className="show-certificate" onClick={() => setShowCertificate(false)}>
      <img src={require(`static/images/certificates/${showCertificate}.jpg`)} alt="" />
    </div>}
    <Footer history={props.history} />
  </div>
}