import React, { useState } from 'react'
import './products.scss'
import { categoryList } from 'utils/constants'
import Footer from 'components/footer'
import PageHead from 'components/pageHead'

export default function Products(props) {
  const [showModal, setShowModal] = useState(false)

  return <div className="products-page">
    <PageHead title="Products" description="DN Associates offers all speciality therapies and niche products for critical/life threatening ailments." />
    <div className={`overlay ${showModal ? 'show' : 'hide'}`}>
      <div className="modal">
        <div className="header">
          <div className="left">
            <h3>{showModal && showModal.name}</h3>
            <p>{`${showModal && showModal.modalDataSize()} ${showModal && showModal.modalDataSize() > 1 ? 'products' : 'product'}`}</p>
          </div>
          <img className="cursor-pointer" onClick={() => setShowModal(false)} src={require('static/images/products/modal-close.svg')} alt="close" />
        </div>
        <div className="row heading">
          <div className="company font-bold">Company</div>
          <div className="col font-bold">Products</div>
        </div>
        <div className="modal-data">
          {showModal && showModal.modalData.map((data, index) => {
            return <div className="row" key={index}>
              <div className="company">{data.companyName}</div>
              <div className="col">
                {data.products.map((product, index) => {
                  return <div key={index}>{product}</div>
                })}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
    <div className="banner">
      <h1>Our products</h1>
      <div className="info-container">
        <div className="row">
          <img src={require('static/images/products-page/first.svg')} alt="" />
          <img src={require('static/images/products-page/second.svg')} alt="" />
          <img src={require('static/images/products-page/third.svg')} alt="" />
        </div>
        <div className="row">
          <p>Focus Therapies</p>
          <p>Exclusive Drugs in this region</p>
          <p>Major International Brands </p>
        </div>
      </div>
    </div>
    <div className="filler"></div>
    <h2>Tap on any of the categories to see the list of available medicines</h2>

    <div className="products-container parent-container with-padding">
      {categoryList.map((product, index) => {
        return <div className="product-card" key={index} onClick={() => setShowModal(product)}>
          <img src={require(`static/images/products-page/${product.imageUrl}.svg`)} alt="" />
          <div className="info">
            <h3>{product.name}</h3>
            <p>{`${product.modalDataSize()} ${product.modalDataSize() > 1 ? 'products' : 'product'}`}</p>
          </div>
        </div>
      })}
    </div>
    <Footer history={props.history} />
  </div>
}