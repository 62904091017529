import React from 'react'
import './footer.scss'
import { bannerFooter } from 'utils/constants'

export default function Footer(props) {
  return <div className="footer-component">
    <div className="col">
      <img className="logo" src={require('static/images/logos/logo.svg')} alt="logo" />
      <div className="social-links">
        <img className="cursor-pointer" onClick={() => window.open('https://www.facebook.com/DN-Associates-599975816707172/')} src={require('static/images/social-links/facebook.png')} alt="logo" />
        <img className="cursor-pointer" src={require('static/images/social-links/twitter.png')} alt="logo" />
      </div>
      <p className="copy-right">&copy; DN Associates 2020. All rights reserved.</p>
    </div>
    <div className="col">
      <h3>Company</h3>
      <div className="footer-links">
        <p onClick={() => props.history.push('/about-us')} className="cursor-pointer font-medium">About us</p>
        <p onClick={() => props.history.push('/products')} className="cursor-pointer font-medium">Products</p>
        <p onClick={() => props.history.push('/contact-us')} className="cursor-pointer font-medium">Contact us</p>
      </div>
    </div>
    <div className="col">
      <h3 className="service">Services</h3>
      <div onClick={() => props.history.push('/services')} className="cursor-pointer services-container">
        <div className="column">
          {bannerFooter.slice(0, 2).map((service, index) => {
            return <div className="service" key={index}>
              <img src={require(`static/images/forte-icons/${service.imageUrl}.svg`)} alt="forte-icon" />
              <div className="font-medium">{service.text}</div>
            </div>
          })}
        </div>
        <div className="column">
          {bannerFooter.slice(2, 5).map((service, index) => {
            return <div className="service" key={index}>
              <img src={require(`static/images/forte-icons/${service.imageUrl}.svg`)} alt="forte-icon" />
              <div className="font-medium">{service.text}</div>
            </div>
          })}
        </div>
      </div>
    </div>
    <div className="social-links-phone">
      <img src={require('static/images/social-links/facebook.png')} alt="logo" />
      <img src={require('static/images/social-links/twitter.png')} alt="logo" />
    </div>
    <p className="copy-right-phone">&copy; DN Associates 2020. All rights reserved.</p>
  </div>
}