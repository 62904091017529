import 'scss/main.scss'
import './App.scss';
import React from 'react';
import Router from 'router/router';

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;