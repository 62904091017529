import React, { PureComponent } from 'react'
import './about-us-card.scss'
import { cardData } from 'utils/constants'

class AboutUsCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      collapseIndex: 0
    }
  }

  render() {
    return <div className="about-us-card">
      {cardData.map((data, index) => {
        return <div
          style={{ background: data.backgroundColor }}
          className={`card 
          ${this.state.collapseIndex === index ? 'collapsed' : ''} 
          ${(this.state.collapseIndex === 0 && index === 2) || (this.state.collapseIndex === 2 && index === 0) ? 'last-card' : ''}
          `}
          key={index}
          onMouseEnter={() => index !== this.state.collapseIndex && this.setState({ collapseIndex: index })}
        // onClick={() => index !== this.state.collapseIndex && this.setState({ collapseIndex: index })}
        >
          <div className="left">
            <div>
              <img src={require(`static/images/about-us-card/icon-${data.imageUrl}.svg`)} alt="icon" />
              <h2 className="font-bold">{data.title}</h2>
              {this.state.collapseIndex === index && <p>{data.text}</p>}
            </div>
          </div>
          <div className="right">
            <img src={require(`static/images/about-us-card/${data.imageUrl}.png`)} alt="" />
          </div>
        </div>
      })}
    </div>
  }
}

export default AboutUsCard