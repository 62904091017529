import React, { useState, useEffect } from 'react'
import './tab-component.scss'

export default function TabComponent(props) {
  const [activeTab, setActiveTab] = useState(0)
  const [animationClass, setAnimationClass] = useState('')

  useEffect(() => {
    setAnimationClass('animate')
    setTimeout(() => setAnimationClass(''), 700)
  }, [activeTab])

  return <div className={`tab-component ${props.about ? 'about' : ''}`}>
    <div className="tabs-container">
      {props.tabs.map((tab, index) => {
        return <div key={index} className={`tabs ${activeTab === index ? 'selected' : ''} ${index === activeTab + 1 ? 'round-left' : ''} ${index === activeTab - 1 ? 'round-right' : ''}`} onClick={() => setActiveTab(index)}>
          <img src={require(`static/images/${props.about ? 'tab' : 'forte-icons'}/${props.about ? 'about-tab-' + tab.imageUrl : tab.imageUrl}.svg`)} alt="" />
          <p>{tab.text}</p>
          {/* <div className="tab-bg"></div> */}
        </div>
      })}
    </div>
    <div className="tab-data-section">
      <div className={`left ${animationClass}`}>
        <div className="tag none">{props.about ? 'Customer base' : 'Services'}</div>
        <div className="left-text">
          <h1>{props.tabData[activeTab].title}</h1>
          <p>{props.tabData[activeTab].text}</p>
        </div>
        {!props.about && <button onClick={() => props.history.push('/services')} className="light font-medium">Read more<span><img src={require('static/images/icons/arrow.svg')} alt="arrow" /></span></button>}
      </div>
      <div className={`right ${animationClass} right-section`}>
        {props.about && props.tabData[activeTab].list ?
          <div className="list">
            {props.tabData[activeTab].list.map((element, index) => {
              return <p key={index}>{element}</p>
            })}
          </div>
          :
          <img src={require(`static/images/tab/${props.about ? 'about-' : ''}${props.tabs[activeTab].imageUrl}.${props.about ? 'svg' : 'png'}`)} alt="" />
        }
      </div>
    </div>
  </div>
}