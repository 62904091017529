import React, { useState, useEffect } from 'react'
import './home.scss'
import { bannerFooter } from 'utils/constants'
import { ReactComponent as Arrow } from 'static/images/icons/arrow.svg'
import AboutUsCard from 'components/aboutUsCard'
import Footer from 'components/footer'
import ReachOutToUs from 'components/reachOutToUs/reachOutToUs'
import { products } from 'utils/constants'
import TabComponent from 'components/tabComponent'
import { tabProductData } from 'utils/constants'
import PageHead from 'components/pageHead'

export default function Home(props) {
  const [showLoader, setShowLoader] = useState(null)

  useEffect(() => {
    if (window.sessionStorage.getItem("firstLoadDone") === null) {
      setShowLoader(true)
      document.getElementsByTagName("body")[0].style.overflow = "hidden";

      setTimeout(() => {
        document.getElementsByTagName("body")[0].removeAttribute("style");
        setShowLoader(false)
        window.sessionStorage.setItem("firstLoadDone", 1)
      }, 2000)
    } else {
      setShowLoader(false)
    }
  }, [])

  return (showLoader === true || showLoader === false) && <div className="home-page">
    <PageHead title="DN Associates" description="DN Associates is a leading supplier for all your speciality healthcare needs. We offer a wide array of authentic products and services for your healthcare system." />
    {/* start of first screen section */}
    <div className="banner-section">
      <div className={`left ${window.screen.width > 768 && showLoader ? 'hide' : ''}`}>
        <div></div>
        <div>
          <h1 className="font-medium">Inspiration, care and compassion delivered</h1>
          <p className='font-18'>We’re making this happen every day by touching virtually every aspect of healthcare.</p>
        </div>
        <div className="scroll-down-section">
          <Arrow />
          <p className="font-18">Scroll down</p>
        </div>
      </div>
      <div className={`right ${window.screen.width > 768 && showLoader ? 'center' : ''}`}>
        <div className="right-section">
          <img className="bg" src={require('static/images/animated-logo/colors.svg')} alt="logo" />
          <img className="dna" src={require('static/images/animated-logo/dna.svg')} alt="logo" />
          <img className="shadow" src={require('static/images/animated-logo/shadow.png')} alt="logo" />
          <img className="bottom-shadow" src={require('static/images/animated-logo/bottom-shadow.svg')} alt="logo" />
        </div>
      </div>
    </div>
    <div className="banner-footer">
      <div className="parent-container with-padding">
        {bannerFooter.map((forte, index) => {
          return <div className="forte-container cursor-pointer" key={index} onClick={() => props.history.push('/services')}>
            <img src={require(`static/images/forte-icons/${forte.imageUrl}.svg`)} alt="forte-icon" />
            <div>{forte.text}</div>
          </div>
        })}
      </div>
    </div>
    {/* end of first screen section */}
    <div className="about-us-section parent-container">
      <div className="tag">About us</div>
      <h1>Values that hold us together</h1>
      <AboutUsCard />
      <button onClick={() => props.history.push('/about-us')} className="light font-medium">Read more<span><img src={require('static/images/icons/arrow.svg')} alt="arrow" /></span></button>
    </div>
    <div className="parent-container">
      <TabComponent history={props.history} tabs={bannerFooter} tabData={tabProductData} />
    </div>
    {/* speciality medicine section */}
    <div className="product-section parent-container with-padding">
      <div className="left-section">
        <div className="tag">Products</div>
        <h2>Speciality medicines</h2>
        <p>Trust us to find whatever medicine you need, any time.</p>
      </div>
      <div className="right-section">
        <div className="row">
          {products.slice(0, 2).map((product, index) => {
            return <div key={index}>
              <img src={require(`static/images/products/${product.imageUrl}`)} alt="" />
              <p className="font-medium">{product.text}</p>
            </div>
          })}
        </div>
        <div className="row">
          {products.slice(2, 5).map((product, index) => {
            return <div key={index}>
              <img src={require(`static/images/products/${product.imageUrl}`)} alt="" />
              <p className="font-medium">{product.text}</p>
            </div>
          })}
        </div>
      </div>
    </div>
    <div className="parent-container with-padding mb-100">
      <button className="light font-medium" onClick={() => props.history.push('/products')}>Read more<span><img src={require('static/images/icons/arrow.svg')} alt="arrow" /></span></button>
    </div>

    <div className="parent-container with-padding">
      <ReachOutToUs />
    </div>
    <Footer history={props.history} />
  </div>
}