// header links to pages
export const headerRoutes = [
  { text: 'about us', path: 'about-us' },
  { text: 'products', path: 'products' },
  { text: 'services', path: 'services' },
  { text: 'contact us', path: 'contact-us' }
]

export const bannerFooter = [
  { text: 'Pharmaceutical distribution', imageUrl: 'first' },
  { text: 'Pharmacy services', imageUrl: 'second' },
  { text: 'Institutional sales', imageUrl: 'third' },
  { text: 'Speciality Drugs and Biopharma', imageUrl: 'fourth' }
]

export const tabProductData = [
  {
    title: 'Pharmaceutical distribution',
    text: 'When you partner with us, your pharmacy, hospital or medical practice benefits from access to a wide selection of drug products, delivered safely on time',
    image: 'first'
  },
  {
    title: 'Pharmacy services',
    text: 'Our goal is to elevate the delivery of healthcare services. We deploy resources, personnel, and business solutions to do just that. Our business runs on a patient-centric model. We focus on providing pharmacy solutions for large and small healthcare systems, as well as retail and specialty environments',
    image: 'second'
  },
  {
    title: 'Institutional sales',
    text: 'Maintaining collaborative, partner-level relationships with hospitals and health systems offers strategic advantages. We understand the relationship hospitals and health systems have with their business partners and work to strengthen them',
    image: 'third'
  },
  {
    title: 'Speciality Drugs and Biopharma',
    text: 'We ensure lifesaving drugs reach hospitals, patients, physician practices, specialty pharmacies, and medical clinics safely, securely, and on-time with our strategic specialty drug distribution system',
    image: 'fourth'
  }
]

export const tabAboutData = [
  {
    title: 'Government hospitals',
    text: 'Institutional sale is our forte. We have delivered with precision and excellence since 1991. We are prominent distributors to the SGPGIMS, RMLIMS, KGMU, Armed Forces, CGHS, ESI, Railways, and most major government institutes & hospitals in Uttar Pradesh.',
    list: [
      'Sanjay Gandhi Postgraduate Institute of Medical Sciences Lucknow',
      'Dr.Ram Manohar Lohia Institute of Medical Sciences Lucknow',
      'King George\'s Medical University Lucknow',
      'Super Speciality Cancer Institute & Hospital Lucknow',
      'Homi Bhabha Cancer Hospital, Varanasi',
      'Armed Forces Medical Depot Lucknow',
      'Command Hospital Lucknow',
      'Base Hospital Lucknow',
      'Central Government Health Scheme(CGHS)',
      'Employees State Insurance Corporation',
      'North Central Railway, Allahabad',
      'Banaras Hindu University, Varanasi',
      'Hindustan Aeronautics Limited(LUCKNOW & KANPUR)',
      'Research Designs and Standards Organisation(RDSO) Lucknow',
      'Modern Rail Coach Factory Raebareli',
      'Maharana Pratap District Hospital Bareilly',
      'Bharat Heavy Electricals Limited',
      'Council of Scientific and Industrial Research',
      'L.P.S.Institute Of Cardiology Kanpur',
      'Uttar Pradesh University of Medical Sciences Saifai Itawah',
      'Super Speciality Paediatric Hospital & Post Graduate Teaching Institute Nodia'
    ]
  },
  {
    title: 'Pharmacies',
    text: 'We are authorized vendors to Amrit Pharmacy (a Government of India enterprise), Lifecraft Healthcare Pvt, Linnet Umang pharmacy, Chandan Pharmacy, Medlife Wellness Retail Pvt. Ltd and to retail pharmacies spread across the region',
    list: [
      'Medlife International Pvt Ltd',
      'AMRIT PHARMACY(LUCKNOW, ALLAHABAD, JHASI, MEERUT, VARANSI, AIIMS RAIPUR, AGRA, KANPUR, AIIMS RAIBREALLY, JHASI)',
      'LINNET UMANG PHARMACY(LLP)',
    ]
  },
  {
    title: 'Corporate hospitals',
    text: 'We are distributors to almost all major corporate hospitals across the state of Uttar Pradesh. We have a robust supply chain to meet the frequent & emergent needs of this sector',
    list: [
      'MEDANTA & LIFECRAFT, Lucknow',
      'Apollomedics Super Speciality Hospital Lucknow',
      'Sahara Hospital Lucknow',
      'Regency Health Super Speciality Hospital',
      'Chandan Hospital',
      'Vivekanand Polyclinic & Institute of Medical Sciences ',
      'Charak Hospital',
      'Shekhar Hospital',
      'St Joseph Hospital',
      'Fatima Hospital ',
      'Atharv Hospital Allahabad',
      'Hindalco Industries',
      'Rama Hospital Kanpur'
    ]
  },
  { title: 'Patient supplies', text: 'We are distributors for many specialty drugs that need to be delivered to patients directly. For Patients with critical ailments,these drugs are delivered at their homes and infusion centers directly. Care is taken to give timely results. The drug are delivered in prescribed temperature conditions,with compassion. Such patients are provided help through patient support programs of the company. Patients with financial needs are supported through Non-Banking Financial Companies (NBFCs)' }
]

export const tabAboutHeader = [
  { text: 'Government hospitals', imageUrl: 'first' },
  { text: 'Pharmacies', imageUrl: 'second' },
  { text: 'Corporate hospitals', imageUrl: 'third' },
  { text: 'Patient supplies', imageUrl: 'fourth' }
]

export const cardData = [
  { title: 'Patient - first approach', text: 'The patient experience consummates with accurate medication, delivered efficiently, with compassion and human connect.', backgroundColor: '#050e42', imageUrl: 'first' },
  { title: 'Operational excellence', text: 'We strive to build teamwork, communication and processes that strengthen our engagement & deliver excellence, always', backgroundColor: '#041261', imageUrl: 'second' },
  { title: '24/7 prompt services', text: 'Our approach is to eliminate latency in the existing pharmaceutical supply chain and deliver right medication timely.', backgroundColor: '#132696', imageUrl: 'third' },
  { title: 'Ethics and compliance', text: 'Ethics & compliance are the main attributes for our company. Transparency in our operations is what enables patients and healthcare providers to trust and partner with us.', backgroundColor: '#3655fe', imageUrl: 'fourth' }
]

export const reachOutToUs = [
  { text: 'Institution Sales', imageUrl: 'first.svg', subject: 'For general query' },
  { text: 'Pharmacy Sales', imageUrl: 'second.svg', subject: 'For pharmacy' },
  { text: 'Wholesale', imageUrl: 'third.svg', subject: 'For wholesale' },
]

export const products = [
  { text: 'Neuroscience', imageUrl: 'first.svg' },
  { text: 'Pain management', imageUrl: 'second.svg' },
  { text: 'GI & Hepato', imageUrl: 'third.svg' },
  { text: 'Gastroenterology', imageUrl: 'fourth.svg' }
]

export const categoryList = [
  {
    name: 'Nephrology and transplant',
    imageUrl: 'prod-1',
    modalData: [
      {
        companyName: 'NOVARTIS',
        products: [
          'MYFORTIC 180 MG TAB',
          'MYFORTIC 360 MG TAB',
          'SANDIMMUN NEORAL 100MG CAP',
          'SANDIMMUN NEORAL 25MG CAP',
          'SANDIMMUN NEORAL 50MG CAP',
          'SANDIMMUN NEORAL LIQ*',
          'CERTICAN 0.25 MG*',
          'CERTICAN 0.50 MG*',
          'CERTICAN 0.75*',
          'SIMULECT LYVI 20',
          'TACSANT*0.5MG',
          'TACSANT*1MG CAP.'
        ]
      },
      {
        companyName: 'ROCHE',
        products: [
          'CELLCEPT 250 MG TAB*',
          'CELLCEPT 500 MG TAB*',
          'CELLCEPT SUSPENSION 1 GM',
          'VALCYTE TAB 450 MG*'
        ]
      },
      {
        companyName: 'SANOFI',
        products: [
          'RENVELA 0.8G SACHET',
          'RENVELA 800MG',
          'THYMOGLOBULIN 25MG',
          'THYMOGLOBULINE 5MG',
          'THYROGEN .9MG/1ML'
        ]
      }
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Oncology', imageUrl: 'prod-2',
    modalData: [
      {
        companyName: 'NOVARTIS',
        products: [
          'REVOLADE 50MG',
          'AFINITOR 10',
          'AFINITOR 5MG',
          'FEMARA 2.5 MG',
          'JAKAVI 20MG',
          'JAKAVI TAB 15MG',
          'JAKAVI TAB 5MG',
          'REVOLADE FCT 25 MG',
          'REVOLADE FCT 50MG',
          'TASIGNA HGC 150MG DUP',
          'TASIGNA HGC 200MG',
          'TYKERB 250MG',
          'TYKERB FCT 250 MG',
          'VOTRIENT 400MG *',
          'ZOMETA 4MG RTU'
        ]
      },
      {
        companyName: 'BOEHRINGER',
        products: [
          'XOVOLTIB 20MG',
          'XOVOLTIB 30MG',
          'XOVOLTIB 40MG',
          'XOVOLTIB 50MG'
        ]
      },
      {
        companyName: 'DR.REDDY',
        products: [
          'DOCETERE RFA 120 INJ',
          'DOCETERE RFA 80 INJ',
          'GRAFEEL 1ML INJ',
          'GRAFEEL 1ML VIAL',
          'CAPIIBINE 10 TAB,',
          'CAPIIBINE 30 TAB',
          'CRESP-100 MG INJ',
          'CRESP-200 MG INJ',
          'CYTOGEM-1000 MG INJ',
          'CYTOGEM-200MG INJ',
          'DACOTIN 100MG INJ.',
          'DACOTIN 50MG INJ',
          'IRNOCAM 100MG',
          'IRNOCAM 40 MG',
          'MITOTAX 100 INJ',
          'MITOTAX 250 INJ',
          'MITOTAX 30 INJ',
          'MITOTAX 300 INJ',
          'PEG-GRAFEEL 6 MG INJ',
          'REDITUX 100MG INJ',
          'REDITUX 500MG INJ',
          'REDITUX 600MG INJ'
        ]
      },
      {
        companyName: 'EISAI',
        products: [
          'HALAVEN 0.44MG',
          'LENVIMA 10 MG',
          'LENVIMA 4MG'
        ]
      },
      {
        companyName: 'ELI LILLY', products: [
          'ALIMTA 500MG',
          'ALIMTA INJ 100MG',
          'GEMCITE 1 GM',
          'GEMCITE 200 MG'
        ]
      },
      {
        companyName: 'NATCO',
        products: [
          'BANDRONE 150',
          'BANDRONE 50MG',
          'BANDRONE INJ',
          'BORTENAT 2MG',
          'BORTENAT 3.5MG',
          'CARFILNAT INJ 60MG',
          'DENOPSY TAB 14MG',
          'DESIFER 100MG',
          'DESIFER 400MG',
          'ERLONAT 150MG',
          'ERLONAT IP 100 MG',
          'FULVENAT 250 MG INJ',
          'FULVENAT 250MG INJ',
          'GEFTINAT 250',
          'KABANAT INJ  60MG/1.5ML',
          'LENALID 10MG',
          'LENALID 15 MG',
          'LENALID 25MG',
          'LENALID 5MG',
          'POMALID 1MG',
          'POMALID 2MG TAB',
          'POMALID 4 MG',
          'POSANAT INJ',
          'RASBURNAT 1.5MG INJ',
          'TEMONAT CAP 100MG',
          'TEMONAT CAP 20MG',
          'TEMONAT CAP 250MG',
          'VEENAT 100 MG',
          'VEENAT 400 MG'
        ]
      },
      {
        companyName: 'PFIZER', products: [
          'ADRIAMYCIN 10MG',
          'ADRIAMYCIN 10MG / RTU',
          'ADRIAMYCIN 50MG',
          'ADRIAMYCIN 50MG / RTU',
          'ATGAM 250MG / 5ML',
          'CAMPTO 100MG / 5ML(CYTOSAFE)',
          'CAMPTO 40MG',
          'CAMPTO INJ.40MG / 2ML(CYTOSAFE)',
          'CARBOPLATIN 150MG',
          'CARBOPLATIN 450MG',
          'CYTOSAR 1 GM INJ',
          'CYTOSAR 100MG',
          'DAUNOMYCIN 20MG',
          'FARMORUBICIN 10MG',
          'FARMORUBICIN 50MG INJ.',
          'FARMORUBICIN RTU50MG',
          'FARMORUBICIN - RTU10MG',
          'INLYTA 5MG',
          'VELTIP 2 MG INJ X1VL'
        ]
      },
      {
        companyName: 'RELIANCE', products: [
          'BORTIREL 2',
          'ERLOTIREL 100',
          'ERLOTIREL 150',
          'PEG RELIGRAST 6MG',
          'RELICITABINE 500',
          'RELIDOMIDE 10MG',
          'RELIDOMIDE 25MG',
          'RELIDOMIDE 5 MG',
          'RELIGRAST 300MCG',
          'RELITREXED 100MG',
          'RELITREXED 500 MG',
          'TEMOREL 140',
          'TEMOREL 180',
          'TEMOREL 250'
        ]
      },
      {
        companyName: 'ROCHE', products: [
          'TARCEVA 100',
          'TARCEVA 150 MG',
          'XELODA 500 MG ROCHE'
        ]
      },
      {
        companyName: 'SUN', products: [
          'BEVETEX 100',
          'BEVETEX 300',
          'LIPODOX 20 MG',
          'LIPODOX 50',
          'LIPODOX INJ'
        ]
      },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Cancer Immunotherapy',
    imageUrl: 'prod-3',
    modalData: [
      { companyName: 'NOVARTIS', products: ['KRYXANA FCT 200 MG'] },
      {
        companyName: 'ROCHE', products: [
          'ALECENSA CAPSULES',
          'TECENTRIQ 1200MG'
        ]
      },
      {
        companyName: 'BMS', products: [
          'OPDYTA 100 MG',
          'OPDYTA 40 MG',
          'SPRYCEL 50MG TAB',
          'SPRYCEL 70MG TAB'
        ]
      },
      {
        companyName: 'JOHNSON &', products: [
          'DARZALEX 1*100 MG',
          'DARZALEX 400 MG INJ',
          'IMBRUVICA 140 MG',
          'IMBRUVICA 140 MG'
        ]
      },
      { companyName: 'MYLAN', products: ['HERTRAZ 150', 'HERTRAZ 440'] },
      {
        companyName: 'PFIZER', products: [
          'PALBACE 125MG',
          'PALBACE 125MG',
          'SUTENT 12.5MG',
          'SUTENT 25 MG',
          'SUTENT 50MG'
        ]
      },
      {
        companyName: 'RELIANCE', products: [
          'BEVACIREL 100MG',
          'BEVACIREL 400',
          'RITUXIREL INJ 100MG',
          'RITUXIREL INJ 500MG',
          'TRASTUREL 150mg',
          'TRASTUREL 440MG'
        ]
      },
      {
        companyName: 'ROCHE', products: [
          'HERCLON 440 MG ROCHE',
          'KADCYLA 100 MG',
          'KADCYLA 100****',
          'KADCYLA 160****',
          'KADCYLA 160MG',
          'PERJETA 420 MG*',
          'RISTOVA 100MG*',
          'RISTOVA 500 MG*'
        ]
      },
      {
        companyName: 'AstraZeneca', products: [
          'LYNPARZA',
          'Imfinzi'
        ]
      }
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Endocrinology and metabolic diseases', imageUrl: 'prod-4',
    modalData: [
      {
        companyName: 'Boehringer', products: [
          'JARDIANCE 10',
          'JARDIANCE 25',
          'JARDIANCE MET 12.5/1000',
          'JARDIANCE MET 12.5/500 MG',
          'JARDIANCE MET 5/500'
        ]
      },
      {
        companyName: 'ELI LILLY', products: [
          'HUMATROPE 18IU',
          'HUMATROPE 36 IU',
          'HUMALOG 200U/ML',
          'HUMALOG CART.',
          'HUMALOG KWIK PEN (SINGLE)',
          'HUMALOG KWIK PEN',
          'HUMALOG MIX 25 CART',
          'HUMALOG MIX 25 KWIK PEN',
          'HUMALOG MIX 50 CART',
          'HUMALOG MIX 50 KWIK PEN',
          'TRULICITY 0.75MG/0.5ML',
          'TRULICITY 1.5MG'
        ]
      },
      {
        companyName: 'JOHNSON &', products: [
          'INVOKANA 100MG',
          'INVOKANA 100MG',
          'VOKANAMET 50/1000MG',
          'VOKANAMET 50/500MG'
        ]
      },
      {
        companyName: 'NOVO', products: [
          'HUMAN ACTRAPID 40IU 10ML',
          'HUMAN ACTRAPID PENFIL',
          'FIASP 100IU VIAL',
          'FIASP FLEXTOUCH 100IU',
          'FIASP PENFILL 100IU',
          'HUMAN ACTRAPID 40 IU',
          'HUMAN ACTRAPID FLEXIPEN',
          'HUMAN ACTRAPID PENFILL',
          'HUMAN MIXARD 100 IU',
          'HUMAN MIXTARD 40 IU',
          'HUMAN MIXTARD 50/50  40IU',
          'INSULATARD 40IU',
          'INSULATARD FLEXPEN 3ML',
          'LEVEMIR FLEXPEN',
          'MIXTARD 30 FLEXPEN',
          'MIXTARD 30 HM PENFILL',
          'MIXTARD 50HM PENFILL',
          'NOVO EIGHT ADMINISTRATION SET',
          'NOVOMIX 30 FLEXPEN',
          'NOVOMIX 30 PENFILL',
          'NOVOMIX 50 FLEXPEN',
          'NOVOMIX 50/50 PENFIL',
          'NOVOPEN 4',
          'NOVORAPID 100IU VIAL',
          'NOVORAPID FLEXPEN SINGLE',
          'NOVORAPID FLEXPENS',
          'NOVORAPID PENFIL',
          'RYZODEG FLEXTOUCH NOVO',
          'RYZODEG PENFILL',
          'TRESIBA 3ML',
          'TRESIBA FLEXTOUCH',
          'TRESIBA PENFILL',
          'VICTOZA LIRAGLUTIDE'
        ]
      },
      {
        companyName: 'SANOFI', products: [
          'APIDRA U100 SOLOSTAR',
          'APIDRA CARTRIDGE',
          'APIDRA SOLOSTAR300Iu/3ML INJ.',
          'INSUMAN 25/75',
          'INSUMAN COMBO 25',
          'INSUMAN RAPID CART',
          'LANTUS 10ML',
          'LANTUS CARTRIDGE',
          'LANTUS U100 SOLOSTAR',
          'TOUJEO 300IU/ML SOLOSTAR'
        ]
      },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Virology', imageUrl: 'prod-5',
    modalData: [
      {
        companyName: 'MYLAN', products: [
          'MYHEP DVIR',
          'MYDEKLA 60MG',
          'MYHEP',
          'MyHep ALL',
          'MYHEP LVIR 28'
        ]
      },
      {
        companyName: 'NATCO', products: [
          'NATCLOVIR INJ 500MG',
          'NATFLU 75MG',
          'HEPCINAT 400MG',
          'HEPCINAT PLUS TAB',
          'HEPCINAT-LP 400MG',
          'HERDUO 250MG',
          'NATDAC 60MG',
          'NATDECITA INJ 50MG/VIAL',
          'SORAFENAT 200 MG',
          'SORAFENAT 200MG TAB',
          'TAFNAT 25MG TAB',
          'TENELNAT M 20/500',
          'TERAVIR 300MG',
          'VELPANAT TAB'
        ]
      },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Hematology', imageUrl: 'prod-2',
    modalData: [
      {
        companyName: 'Boehringer', products: [
          'METALYSE INJ 40 MG',
          'PRADAXA 75 MG',
          'PRADAXA CAP 110 MG',
          'PRADAXA CAP 150 MG'
        ]
      },
      {
        companyName: 'BAYER', products: [
          'XARELTO 15MG (2*10)',
          'XARELTO 20MG (2*14)',
          'XARELTO 10MG',
          'XARELTO 15 (60+38)'
        ]
      },
      {
        companyName: 'NOVO', products: [
          'NOVO SEVEN 1MG',
          'NOVO SEVEN 2 MG'
        ]
      },
      { companyName: 'PFIZER', products: [] },
      { companyName: 'RELIANCE', products: [] },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Central Nervous System', imageUrl: 'prod-10',
    modalData: [
      {
        companyName: 'EISAI', products: [
          'AVONEX PEN',
          'AVONEX PFS',
          'FYCOMPA 2MG',
          'FYCOMPA 4MG',
          'FYCOMPA 6MG',
          'PLEGRIDY 63/94G/',
          'PLEGRIDY PF PEN0.5ML 125G',
          'TECFIDERA 120MG',
          'TECFIDERA 240MG',
          'TYSABRI 300 MG',
          'ZONEGRAN TAB'
        ]
      },
      {
        companyName: 'JOHNSON &', products: [
          'CONCERTA 18MG',
          'CONCERTA 36MG',
          'CONCERTA 54MG',
          'INVEGA SUSTENA 100',
          'INVEGA SUSTENA 150 MG',
          'INVEGA SUSTENNA 75 MG',
          'RISPERDAL CONSTA 25 MG',
          'RISPERDAL CONSTA 50MG'
        ]
      },
      { companyName: 'RELIANCE', products: ['16310 RELIBETA 30 MCG'] }
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Anemia',
    imageUrl: 'prod-2',
    modalData: [
      { companyName: 'DR.REDDY', products: ['CRESP 40 INJ', 'CRESP 60MG'] },
      { companyName: 'EISAI', products: ['QUICOBAL'] },
      { companyName: 'RELIANCE', products: ['RELIPOIETIN 10K', 'RELIPOIETIN 2K', 'RELIPOIETIN 4000 IU'] },
      { companyName: 'ROCHE', products: ['MIRCERA 100 MCG ROCHE', 'MIRCERA 50 MCG ROCHE', 'MIRCERA 75 MCG ROCHE'] },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Human Plasma Derivates ',
    imageUrl: 'prod-2',
    modalData: [
      {
        companyName: 'RELIANCE', products: [
          'ADALIREL 20 MG',
          'ADALIREL 40mg',
          'ALBUREL 20% 100 ML',
          'ALBUREL 20% 50ML',
          'ALBUREL 20% 50ML*',
          'IMMUNOREL 0.5GM',
          'IMMUNOREL 10%',
          'IMMUNOREL 10GM',
          'IMMUNOREL 10GM',
          'IMMUNOREL 1GM',
          'IMMUNOREL 2.5GM',
          'IMMUNOREL 5%',
          'IMMUNOREL 5%',
          'IMMUNOREL 5%',
          'IMMUNOREL 5%.',
          'RHOREL 1500 IU Solution',
          'RELISEAL 0.5ML',
          'RELISEAL 1ML'
        ]
      },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Thalassemia / Hemphillia', imageUrl: 'prod-2',
    modalData: [
      {
        companyName: 'NOVARTIS', products: [
          'ASUNRA 400MG TAB',
          'DESFERAL 500MG',
          'OLEPTISS FCT 180MG*',
          'OLEPTISS FCT 360*'
        ]
      },
      {
        companyName: 'ROCHE', products: [
          'HEMLIBRA 30 MG',
          'HEMLIBRA 30 MG*',
          'HEMLIBRA 60 MG',
          'HEMLIBRA VIAL 150MG'
        ]
      },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  {
    name: 'Rheumatology', imageUrl: 'prod-8',
    modalData: [
      { companyName: 'CIPLA', products: ['ACTEMERA  400 MG'] },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  },
  // { name: 'Anti-Infective', imageUrl: 'prod-11' },
  {
    name: 'Respiratory and immuno-inflammatory', imageUrl: 'prod-4',
    modalData: [
      { companyName: 'Boehringer', products: ['CYENDIV 100MG', 'CYENDIV 150MG'] },
      { companyName: 'NOVARTIS', products: ['XOLAIR 150MG'] },
      { companyName: 'AstraZeneca', products: ['Tagrisso'] },
    ],
    modalDataSize: function () { return productCount(this.modalData) }
  }
]

function productCount(modalData) {
  let count = 0
  modalData.forEach(product => count += product.products.length)
  return count
}

export const certificates = [
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth"
]

export const partnerImg = [
  [
    'abbott',
    'akumentis',
    'alkem',
    'allergan',
    'astra-zeneca',
    'baxter',
    'bayer',
    'bdr',
    'becton-dickinson'
  ], [
    'biocon',
    'biological',
    'biorex',
    'boehringer-ingelheim',
    'bpl',
    'bristol',
    'cachet',
    'cadila',
    'cipla',
  ], [
    'cortina-labs',
    'drreddy',
    'eisai',
    'eli-lilly',
    'emcure',
    'ferring-pharmaceuticals',
    'fulford',
    'genova-biopharmaceuticals',
    'gsk',
  ], [
    'ipca',
    'johnson-and-johnson',
    'lundbeck',
    'lupin',
    'mankind',
    'merck',
    'microlabs',
    'msd',
    'msn',
  ], [
    'mylan',
    'natco-pharma',
    'novartis',
    'novo-nordisk',
    'organon',
    'pfizer',
    'piramal',
    'reliance',
    'roche',
  ], [
    'rw',
    'sanofi',
    'shi-mediwear',
    'smith',
    'sun',
    'torrent',
    'ucb',
    'unichem',
    'zydus'
  ]
]

export const serviceData = [
  `<p>When you partner with us, your pharmacy, hospital or medical practice benefits from access to a wide selection of drug products, delivered safely on time.<br/><br/>
  From needles and syringes to gloves, ventilators, drugs & vaccines, we deliver the medical supplies you need to help keep your patients healthy. Our exceptional selection of medical products and pharmaceuticals—along with outstanding delivery service—lets you focus fully on patient care. Our unmatched standard in product access and distribution sets us apart from other distributors in the market.</p>
  <h4>Drug Purchasing and Distribution</h4>
   <p>Pharmacies of all types and sizes have access to a wide selection of high-quality, first-to-market prescription drugs at competitive prices through our comprehensive drug purchasing and distribution solutions. Our sourcing network is supported by long-standing supplier relationships with more than 70 drug manufacturers.</p>
   
  <h4>Value beyond price: A distribution partnership that delivers</h4>
   <p>As healthcare systems continually search for ways to reduce costs, the lure of a low-cost distribution contract should never outweigh the value of a proven partner.
   Even under the best of circumstances, healthcare systems must conquer complex supply chain challenges to provide essential drugs and medical products across the continuum of care. When global drug shortages or disasters arise, the true costs of sacrificing premium distribution services in the name of low pricing can become even more pronounced. By comparing distributors in terms of total value delivered, rather than by costs alone. Healthcare organizations can make more informed decisions to benefit both business performance and patient care.</p>
   
  <h4>Inventory management:</h4>
  <p>We, through their buying power and economies of scale, can maximize efficiency to enable pharmacies to run lean operations while focusing on their core competency of patient care. By consolidating, delivering, and returning orders from multiple manufacturers in packs, we can save pharmacies time and expense since they do not need to expand resources on warehousing or logistics activities. Furthermore, we deliver in a timely manner so that pharmacy staff can serve customers rather than wait for and handle shipments from multiple manufacturers.</p>
   
 <h4>Financial risk management:</h4>
  <p>Distributors serve as a risk-bearing ecosystem intermediary by facilitating three key components of receivables management: controllable risk, uncontrollable risk, and receivables timing.</p>
   
  <h4>Pharmaceutical Cold Chain Packaging:</h4> 
  <p>To meet the need for pharmacies' geographic, environmental, and transportation, we provide a variety of innovative pharmaceutical cold chain packaging services. The ‘qualified’ delivery packs with us, help ensure you receive the medication intact with optimum storage temperatures.</p>
   <h4>Traceability and accuracy:</h4>
  <p>We constantly work to combat counterfeiting, contamination, and adulteration of prescription drugs while working closely with manufacturers.</p>
  `,

  `<p>We ensure lifesaving drugs reach hospitals, patients, physician practices, specialty pharmacies, and medical clinics safely, securely, and on-time with our strategic specialty drug distribution system.
  Our experienced staff offers the reliability you need across all distribution channels, from patients to infusion clinics to major medical centers.</p>
  <h4>A history of proven track records</h4>
  <p>We have the long-standing industry expertise to store and deliver specialty therapy drugs, biological drugs & plasma therapies. We consistently provide best-in-class, strategic distribution solutions for pharmaceutical manufacturers.
  We rigorously govern the quality standards management throughout the entire distribution process, strengthening supply chain performance while adhering strictly to industry guidelines. They’re audited internally and by principle companies for compliance.</p>
   
  
  <h4>Cold Chain Management</h4>
  <p>We provide cold chain management through temperature monitoring systems. Our experienced & trained staff rigorously ensure the selection and validation of shipping containers that comply with manufacturers’ product specifications.
  We also provide fast shipping to provide standard overnight and priority delivery. Virtually 100% of all orders received by the cut-off time of 4:00 p.m. IST are delivered the next business day. The shipment is constantly tracked until it is delivered at the desired destination.</p></br/>
  <p>1.      We only use commercial refrigeration units that are temperature mapped, alarmed, and monitored with calibrated devices, of accredited agencies.<br/><br/></p>
  <p>2.      The cold chain packaging we use maintains a 2-8°C environment regardless of the season or geography and regardless of the amount of product shipped, from a single vial to a large shipment.<br/></p>
   <h4>Non-Banking Financial Corporation (NBFC)</h4>
   <p>We tie-up with NBFC to support patients with limited means. NBFCs offer medical loans to the traditionally un-bankable, using innovative risk assessment tools that allow them to finance people outside the formal banking system. Patients borrow from and repay NBFCs leaving them free to get treated in the place of their choosing.</p>  
  `,
  `<p>Our goal is to elevate the delivery of healthcare services. We deploy resources, personnel, and business solutions to do just that. Our business runs on a patient-centric model. We focus on providing pharmacy solutions for large and small healthcare systems, as well as retail and specialty environments.<br/><br/>
  We have a team of people who have the passion and expertise to get the job done right. We are channel partners to Amrit Pharmacy, Lifecraft Healthcare Pvt. Ltd, Linnet Umang Pharmacy, Medlife Wellness Retail Pvt. Ltd and retail pharmacies spread across the region.</p>`
  ,

  `<p>Institutional sales are required to navigate through newer organizational structures while complying with important regulations. Maintaining collaborative, partner-level relationships with hospitals and health systems offers strategic advantages. We understand the relationship hospitals and health systems have with their business partners and work to strengthen them.
  We have helped to enhance pharmaceutical companies' images, improved their relations with key decision-makers, and helped them find opportunities for collaboration. Our knowledge of the products and understanding of systems has given us the edge over our competitors in the region.
  We have a dedicated team of experienced professionals and the necessary infrastructure in place to guide our channel partners to submit quotations and file tenders with 100% accuracy & success.</p>
  
 <h4>Compliance and transparency:</h4>
 <p>Our strength lies in compliance and transparency.
 Transparent & accurate record-keeping has helped us win the trust of Hospitals, health system stakeholders, and affiliated providers.</p>
 `
]